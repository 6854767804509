<template>
    <div id="app">
        <router-view />

		<!-- MountingPortal mounts here -->
		<div id="app-modal"></div>
        <div id="app-loader-layer"/>

        <dialog-error v-if="error_modal_show"
            :has_header="error_modal_has_header"
            :title="error_modal_title"
            :has_message="error_modal_has_message"
            :message="error_modal_message"
            :button_caption="error_modal_button_caption"
        />

        <dialog-success v-if="success_modal_show" :message="success_modal_message" :auto-show="true" :has_header="success_modal_has_header"/>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dynStorage from '@/utils/storage-dynamic';

import dialogError from '@/dialogs/dialog-error'
import dialogSuccess from '@/dialogs/dialog-success'

export default {
    components: {
        dialogError,
        dialogSuccess,
    },

	created() {
        window.addEventListener('resize', this.setViewport);

        window.addEventListener('storage', this.handleLocalStorageEvent);

        window.addEventListener('unload', this.handleUnload);
    },

    destroyed() {
        window.removeEventListener('resize', this.setViewport);

        window.removeEventListener('storage', this.handleLocalStorageEvent);

        window.removeEventListener('unload', this.handleUnload);
    },

    computed: {
        ...mapGetters([
            'is_auth',
            'is_account',
            'is_app_header_hidden',

            'error_modal_show',
            'error_modal_has_header',
            'error_modal_title',
            'error_modal_has_message',
            'error_modal_message',
            'error_modal_button_caption',

            'success_modal_has_header',

            'success_modal_show',
            'success_modal_message',

            'get_catalog_services_trigger',
            'get_catalog_services_sld_trigger',

            'customer_current_mobile_product_billing_reference',
            'customer_current_mobile_product',
            'is_on_transfer_product_type',
            'is_app_support_service_intercom',
        ]),
    },

	methods: {
		setViewport(){
			this.$store.dispatch('setViewport')
		},

        handleLocalStorageEvent(event) {
            if (!this.is_auth || !event) return

            const { key } = event

            if (key == 'customer_current_mobile_product_uuid') {
                if (this.$route.name == 'onboarding') {
                    this.$router.push({name: 'home'}).then(() => {
                        location.reload()
                    })
                } else {
                    location.reload()
                }
            }
        },

        handleUnload() {
            dynStorage.set('before_reload_route_name', this.$router?.history?.current?.name, null, true);
        },
	},

	watch: {
		'$route.query': {
			immediate: true,
			handler(query) {
				if (query.header === 'hide') {
					this.$store.dispatch('SetIsAppHeaderHidden', true);
				}

				if (query.platform) {
                    this.$store.dispatch('SetPlatform', query.platform);
				}

				if (query.language) {
                    this.$store.dispatch('SetLanguage', { language: query.language });
				}
			}
		},

        '$route': {
            immediate: false,
            handler(route) {
                const { path, name, params } = route

                if (!name) return;

                if (this.is_app_header_hidden && (name == 'help-center' || name == 'help-center-category' || name == 'help-center-article' || name == 'terms' || name == 'privacy' || name == 'terms-special')) return;

                this.$store.dispatch('logAnalyticsEventPageView', { path, name })

                if (this.is_app_support_service_intercom) {
                    if (document.getElementById('intercom-snippet')) return;
    
                    this.$store.dispatch('InitIntercomChat', Boolean(params?.chat));
                } else {
                    if (document.getElementById('ze-snippet')) return;
    
                    this.$store.dispatch('InitZendeskChat', Boolean(params?.chat));
                }
            }
        },

        get_catalog_services_trigger: {
            immediate: false,
            handler(curr_trigger, prev_trigger) {
                if (prev_trigger) {
                    this.$store.dispatch('GetCatalogServices', { force: true })
                }
            }
        },

        get_catalog_services_sld_trigger: {
            immediate: false,
            handler(curr_trigger, prev_trigger) {
                if (prev_trigger) {
                    this.$store.dispatch('GetCatalogServices', { force: true })
                }
            }
        },

        customer_current_mobile_product_billing_reference: {
            immediate: false,
            handler(curr_billing_reference, prev_billing_reference) {
                if (this.is_account && !this.is_on_transfer_product_type && prev_billing_reference && curr_billing_reference) {
                    const [prev_billing_reference_type, prev_billing_reference_id] = prev_billing_reference.split(':')
                    const [curr_billing_reference_type, curr_billing_reference_id] = curr_billing_reference.split(':')

                    if (prev_billing_reference_id && curr_billing_reference_id &&
                        prev_billing_reference_id === curr_billing_reference_id &&
                        prev_billing_reference_type && curr_billing_reference_type &&
                        prev_billing_reference_type !== curr_billing_reference_type) {
                            this.$store.dispatch('showSuccessMessage', { success_message: this.$t('sim_transfer_product_message'), has_header: false })

                            this.$store.commit('SET_CUSTOMER_CURRENT_PRODUCT', {
                                ...this.customer_current_mobile_product,
                            })
                    }
                }
            }
        },
	}
}
</script>

<style lang="scss">
    #app {
        --brand-color-primary: #{$brand-color-primary};
        --accent-color: #{$accent-color};
        --brand-color-white: #{$white};

        --tablet-size: #{$tablet-size};
        --mobile-size: #{$mobile-size};

        position: relative;
        overflow: hidden;

        #app-loader-layer {
            .vue-portal-target:not(:last-of-type) {
                .app-loader__layer {
                    display: none;
                }
            }
        }
    }
</style>
